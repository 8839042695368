import React from 'react'

import Chip from '@material-ui/core/Chip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const CVdownLoad=(props)=>{
    return <Chip color="primary" icon={<CloudDownloadIcon />} label="Download my CV"
    component="a"
    href="https://drive.google.com/file/d/17jdp_nR0mXNSnTnfcGRb55GhsTFKPoxY/view?usp=sharing"
    target='_blank'
    clickable/>
    // <a href='' target='_blank'>Get my CV</a>
}

export default CVdownLoad