import React, { useEffect, useState } from 'react'


// import pic02 from '../images/pic02.jpg'

import WorkCntntLines from './WorkExpLines'


const WorkItem=(props)=>{

const [contentValue,setcontntVal]=useState([])

useEffect(()=>{
    setcontntVal(props.content)
},[])


return <>
    <span className="image main">
            <img src={props.imagename} alt="" />
          </span>
          <b>{props.title}</b><br/>
          <i>{props.subtitle}</i>
          <p>
              <ul>
                {contentValue.map((val)=><WorkCntntLines contentval={val} />)}
                
              </ul>
          </p>
</>
}

export default WorkItem