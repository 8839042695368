import React from 'react'

const ContactIcons=(props)=>{
    return <ul className="icons">
    {/* <li>
      <a
        href="https://twitter.com/HuntaroSan"
        className="icon fa-twitter"
      >
        <span className="label">Twitter</span>
      </a>
    </li> */}
    {/* <li>
      <a href="https://codebushi.com" className="icon fa-facebook">
        <span className="label">Facebook</span>
      </a>
    </li> */}
    {/* <li>
      <a href="https://codebushi.com" className="icon fa-instagram">
        <span className="label">Instagram</span>
      </a>
    </li> */}
    {/* <li>
      <a
        href="https://github.com/codebushi/gatsby-starter-dimension"
        className="icon fa-github"
      >
        <span className="label">GitHub</span>
      </a>
    </li> */}
    <li>
      <a
        href="https://www.linkedin.com/in/ajita-chakladar-35414121/"
        className="icon fa-linkedin"
        target='_blank'
      >
        <span className="label">Linkedin</span>
      </a>
    </li>
    <li>
      <a
        href="mailto:ajitaa3@gmail.com"
        className="icon fa-at"
        target='_blank'
      >
        <span className="label">Email</span>
      </a>
    </li>
  </ul>
}

export default ContactIcons