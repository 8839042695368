import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">
      &copy; Ajita Chakladar. Design: <a href="https://amlanscloud.com" target="_blank">AC</a>.
      Built with: <a href="https://www.gatsbyjs.com/starters/codebushi/gatsby-starter-dimension" target="_blank">Gatsby.js</a>
    </p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
