import PropTypes from 'prop-types'
import React from 'react'

import profpic from '../images/mainprofileimg.png'

import mainlogo from '../images/mainlogo.png'
import Avatar from '@material-ui/core/Avatar';

import AvatarBadge from './AvatarBadge'



const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    {/* <div className="logo"> */}
      {/* <span className="icon fa-address-card"><img src={mainlogo} alt="" /></span> */}
      {/* <Avatar alt="Remy Sharp" src={mainlogo} /> */}
      <AvatarBadge />
      
    {/* </div> */}
    <div className="content">
      <div className="inner">
        <h1>Ajita Chakladar</h1>
        <p>
        “Make stuff people want!” - That’s the mantra I implement in
          everything I create..
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            About Me
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            CV
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
