import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Chip from '@material-ui/core/Chip';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(8),
    },
  },
}));

export default function SimplePaper() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Paper elevation={0} />
      <Paper /> */}
      {/* <Paper elevation={3} > */}
      <Chip color="primary" icon={<AlternateEmailIcon />} label="ajitaa3@gmail.com"/>
      {/* </Paper> */}
    </div>
  );
}
