import React from 'react'

import pic02 from '../images/pic02.jpg'
import pic03 from '../images/Infosys_logo.svg'
import boltlogo from '../images/bolt1.png'
import maxpilogo from '../images/maxpi.png'
import itclogo from '../images/itclogo.png'
import newboltpic from '../images/bolt1.png'

import blankpic from '../images/newblank.jpg'

import WorkExpItem from './WorkExpItems'

const WorkTabCntnt=(props)=>{
    return  <>
        <WorkExpItem imagename={pic03} title='SENIOR ASSOCIATE CONSULTANT' subtitle={'Infosys Limited ( July 2020 -)'} content={['Working as SAP MDM Consultant for maintaining the MDM Legacy Applications andsupporting the Vendor, Material and Customer management apps of Unilever']}/>
        <WorkExpItem imagename={blankpic} title='DIGITAL AND CONTENT MARKETING CONSULTANT' subtitle={'Assetgro Fintech Pvt. Ltd. (May 2020 - June 2020)'} content={['Managed Social media campaigns (Facebook and Instagram) by planning, designing andcreating video and image contents - Increased Facebook Page reach by 300% in one month','Created engaging Email Marketing Campaigns for the participants ( 62.5% increase inparticipating colleges in two weeks )']}/>
        {/* <WorkExpItem imagename={pic03} title='DIGITAL AND CONTENT MARKETING CONSULTANT' subtitle={'Assetgro Fintech Pvt. Ltd. (May 2020 - June 2020)'} content={['Managed Social media campaigns (Facebook and Instagram) by planning, designing andcreating video and image contents - Increased Facebook Page reach by 300% in one month','Created engaging Email Marketing Campaigns for the participants ( 62.5% increase inparticipating colleges in two weeks )']}/> */}
        {/* <WorkExpItem imagename={pic02} title='SALES AND MARKETING INTERN' subtitle={'Inventrom Pvt. Ltd. Bolt IoT (April 2019 - May 2019)'} content={['Executing and monitoring Email and social media advertising on Whatsapp drip campaign and FB Manychat','Managed a virtual campus ambassador program garnering a 7.4% increase in sales','Performed content management and digital marketing through Hubspot']}/> */}
        <WorkExpItem imagename={maxpilogo} title='DIGITAL MARKETING INTERN' subtitle={'Maxpi Technologies Ltd. (Nov 2018 - Dec 2018)'} content={['Content Creation, Content Management, Content Auditing, Social Media Advertising and developing Editorial Calendar']}/>
        <WorkExpItem imagename={itclogo} title='ASSOCIATE IT CONSULTANT' subtitle={'ITC Infotech Pvt. Ltd. (July 2015 - May 2018 )'} content={['Worked as a Quality Assurance Engineer for maintaining the integrity and quality of thesoftware developed.Gathered and conveyed requirements, developed test plans, scriptsand test reports Achieved "SPOT Award" for excellent Business Analysis Skills for the project']}/>
    </>
    {/* <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <b>ASSOCIATE IT CONSULTANT</b><br/>
          <i>ITC Infotech Pvt. Ltd. (July 2015 - May 2018 )</i>
          <p>
          Worked as a
Quality Assurance Engineer
for maintaining the integrity and quality of thesoftware developed.Gathered and conveyed requirements, developed test plans, scriptsand test reports Achieved ‘
SPOT Awar
d
’ for excellent Business Analysis Skills for the project
          </p>
          <p>
            Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
            libero. Mauris aliquet magna magna sed nunc rhoncus pharetra.
            Pellentesque condimentum sem. In efficitur ligula tate urna.
            Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor.
            Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
            libero. Mauris aliquet magna magna sed nunc rhoncus amet feugiat
            tempus.
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
    
}

export default WorkTabCntnt